import api from '../../../api';
import { createAsyncThunk } from '../../utils/wrapper';

export const fetchAppProperties = createAsyncThunk(
	'appProperties/fetch',
	async () => api.getAppProperties(),
	{
		condition: (_arg, { getState }) =>
			!getState().appProperties.fetched && !getState().appProperties.fetching,
	}
);
