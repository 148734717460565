import { createAsyncThunk } from '../../utils/wrapper';
import { CouldNotFetchFaxGroups, FaxGroup, FaxGroupError } from './types';
import api from '../../../api';
import { fetchFaxlines } from '../../modules/faxlines';

export const fetchFaxGroups = createAsyncThunk<
	FaxGroup[],
	{ forceFetch?: boolean },
	{ rejectValue: FaxGroupError }
>(
	'faxGroups/fetch',
	async (
		_args: {
			forceFetch?: boolean;
		},
		{ rejectWithValue, dispatch, getState }
	) => {
		try {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-expect-error
			const fetchFaxLineAction = await dispatch(fetchFaxlines()).payload.promise;

			if (fetchFaxLineAction.type === 'FAXLINES_FETCH_FAILURE') {
				// something went wrong fetching fax lines rejecting action
				return rejectWithValue(CouldNotFetchFaxGroups());
			}

			const faxLines = getState().faxlines.items;
			if (faxLines.length === 0) {
				// No fax lines in account no possible fax group
				return [];
			}

			const groups = (await api.getGroups()).items;

			// combine fax lines with groups to become fax groups
			return groups
				.map(
					group =>
						({
							...group,
							faxLine: faxLines.find(f => f.ownerId === group.id),
						}) as FaxGroup
				)
				.filter(faxGroup => faxGroup.faxLine !== undefined);
		} catch (error) {
			return rejectWithValue(CouldNotFetchFaxGroups());
		}
	},
	{
		condition: ({ forceFetch = false }: { forceFetch?: boolean }, { getState }) => {
			return getState().faxGroups.fetching !== 'pending' || forceFetch;
		},
	}
);
