import classnames from 'classnames';
import React from 'react';
import { usePandaContext } from '../../../contexts/pandaContext';

import classes from './SideBarLogoHeader.module.css';
import { Headline } from '../../../components/heading/Headline';

interface Props {
	logo?: string;
	title: string;
	onChange: () => void;
}

const styles = {
	logo: classnames('object-contain', 'w-full', 'max-h-[12rem]', 'min-h-[3rem]'),
	container: (hasLogo: boolean) =>
		classnames(
			'font-brand',
			'items-center',
			'flex',
			'flex-col',
			'gap-4',
			'p-32',
			'block',
			'relative',
			'border-b',
			'border-dashed',
			'border-neo-color-global-border-neutral-soft-default',
			'rounded-t-lg',
			'w-full',
			hasLogo
				? 'bg-neo-color-web-app-component-sidebarhero-background'
				: 'bg-neo-color-web-app-surface-sidebarhero',
			'radius-top-4',
			'cursor-pointer',
			'before:content-[""]',
			'before:absolute',
			'before:w-full',
			'before:h-full',
			'before:left-0',
			'before:top-0',
			'after:rounded-t-lg',
			'hover:before:bg-neo-color-web-app-component-sidebarhero-logoupload-overlay-background-default',
			'focus-visible:before:bg-neo-color-web-app-component-sidebarhero-logoupload-overlay-background-default',
			'focus-visible:after:ring',
			'focus-visible:after:ring-inset',
			'focus-visible:after:ring-neo-color-global-border-static-focus',
			'select-none',
			classes.logo
		),
	company: (hasLogo: boolean) =>
		classnames(
			'font-bold',
			'text-neo-color-global-content-neutral-intense',
			'text-xl/24',
			hasLogo && 'sr-only'
		),
	label: (hasLogo: boolean) =>
		classnames(
			'text-neo-color-global-content-neutral-moderate',
			'text-base/20',
			hasLogo && 'sr-only'
		),
	skipToContentLink: classnames(
		'sr-only',
		'focus:not-sr-only',
		'focus:absolute',
		'focus:font-bold',
		'focus:no-underline',
		'focus:p-8',
		'focus:p-16',
		'focus:bg-neo-color-global-border-static-focus',
		'focus:text-neo-color-global-content-critical-on-intense',
		'focus:rounded',
		'focus:z-800'
	),
};

export const SideBarLogoHeader = (props: Props) => {
	const { languageKeys } = usePandaContext();
	const labelId = React.useId();

	return (
		<>
			<Headline screenreaderOnly>{props.title}</Headline>
			<a href="#content" className={styles.skipToContentLink}>
				{languageKeys.PANDA_SIDEBAR_NAVIGATION_SKIP_TO_CONTENT_LINK}
			</a>
			<button
				type="button"
				className={styles.container(!!props.logo)}
				onClick={props.onChange}
				aria-labelledby={labelId}
			>
				{props.logo ? <img className={styles.logo} src={props.logo} alt="" /> : null}

				<span className={styles.company(!!props.logo)}>{props.title}</span>
				<span id={labelId} className={styles.label(!!props.logo)}>
					{props.logo ? languageKeys.PANDA_CHANGE_LOGO : languageKeys.PANDA_ADD_LOGO}
				</span>
			</button>
		</>
	);
};
