import { getLocale } from './deprecated';
import { getCountryCodeFromLocale } from './index';

export const findMatchLocation = (needle: string, haystack: string) => {
	if (needle == null || haystack == null) {
		return null;
	}
	const index = haystack.toLocaleLowerCase().indexOf(needle.trim().toLocaleLowerCase());

	if (index < 0) {
		return null;
	}

	return {
		start: index,
		length: needle.trim().length,
	};
};

/**
 * Check if user input (needle) matches a given, formatted phonenumber (haystack).
 * Returns the location of the match within the haystack, so it can be highlighted
 * for the user.
 *
 * This function tries its hardest to be aware of formatting differences, so it
 * matches things like `+491579` within `0157-99999999` or `015` within
 * `+49157 99999999`.
 */
export const findNumberMatchLocation = (needle: string, haystack: string) => {
	const trimmedNeedle = needle.replace(/[^\d]/g, '');
	const possibleNumbers = [trimmedNeedle];

	if (trimmedNeedle.length === 0) {
		return null;
	}

	if (needle.startsWith('+')) {
		possibleNumbers.unshift(`^${trimmedNeedle.substring(1)}`);
		possibleNumbers.unshift(`^${trimmedNeedle.substring(2)}`);
		possibleNumbers.unshift(`^0${trimmedNeedle.substring(1)}`);
		possibleNumbers.unshift(`^0${trimmedNeedle.substring(2)}`);
		possibleNumbers.unshift(`^+${trimmedNeedle}`);
	} else if (needle.startsWith('00')) {
		possibleNumbers.push(`^+${trimmedNeedle.substring(2)}`);
		possibleNumbers.push(`^0${trimmedNeedle.substring(2)}`);
		possibleNumbers.push(`^0${trimmedNeedle.substring(3)}`);
		possibleNumbers.push(`^0${trimmedNeedle.substring(4)}`);
		possibleNumbers.push(`^${trimmedNeedle.substring(2)}`);
		possibleNumbers.push(`^${trimmedNeedle.substring(3)}`);
		possibleNumbers.push(`^${trimmedNeedle.substring(4)}`);
	} else {
		possibleNumbers.push(`^+${trimmedNeedle.substring(1)}`);
		possibleNumbers.push(`^+${getCountryCodeFromLocale(getLocale())}${trimmedNeedle.substring(1)}`);
		possibleNumbers.push(`^0${trimmedNeedle.substring(1)}`);
		possibleNumbers.push(`^0${trimmedNeedle.substring(2)}`);
		possibleNumbers.push(`^${trimmedNeedle.substring(1)}`);
		possibleNumbers.push(`^${trimmedNeedle.substring(2)}`);
	}

	for (const number of possibleNumbers.filter(n => n.length > 1)) {
		const regex = new RegExp(number.split('').join('\\D*?').replace(/\+/, '\\+'));

		const match = regex.exec(haystack);

		if (match !== null) {
			const foundSubstring = match[0];

			return {
				start: haystack.indexOf(foundSubstring),
				length: foundSubstring.length,
			};
		}
	}

	return null;
};
