import { useCallback, useEffect } from 'react';

import {
	AnnouncementDestination,
	ForwardingStep,
	PhonenumberDestination,
	VoicemailDestination,
} from '../../../api/types/forwardings';
import { TimeBasedAcdForwarding } from '../../../views/acds/types';
import { didFetch } from '../../index';
import { useDispatch, useSelector } from '../../utils/wrapper';
import { useCurrentUser } from '../users';
import {
	fetchTimeBasedForwardings,
	updateTimeBasedForwardings as updateTimeBasedForwardingsAction,
	updateTimeBasedSets as updateTimeBasedSetsAction,
} from './actions';
import { fullWeek } from './helpers';
import { convertTimeBasedForwardingsSet } from './mapping';
import { TimeBasedForwardingSet } from './types';

export const defaultAcdAnnouncementDestination: AnnouncementDestination = {
	type: 'ANNOUNCEMENT',
	target: 'provided-wait-timeout',
};

export const defaultAcdAnnouncementHolidayDestination: AnnouncementDestination = {
	type: 'ANNOUNCEMENT',
	target: 'provided-holiday-announcement',
};

const defaultForwardingAnnouncement: TimeBasedAcdForwarding = {
	type: 'timeBased',
	delay: 600,
	destination: defaultAcdAnnouncementDestination,
	priority: 0,
};

function getForwardingStep(
	forwarding: TimeBasedForwardingSet
): ForwardingStep<AnnouncementDestination | VoicemailDestination | PhonenumberDestination> {
	if ('unconditional' in forwarding) {
		return forwarding.unconditional[0] as ForwardingStep<
			AnnouncementDestination | VoicemailDestination | PhonenumberDestination
		>;
	}

	if (forwarding.online.length === 0) {
		return {
			delay: defaultForwardingAnnouncement.delay,
			destination: defaultAcdAnnouncementDestination,
		};
	}

	return forwarding.online[0] as ForwardingStep<
		AnnouncementDestination | VoicemailDestination | PhonenumberDestination
	>;
}

export function timeBasedForwardingSetToAcdForwarding(
	forwarding: TimeBasedForwardingSet
): TimeBasedAcdForwarding {
	const forwardingStep = getForwardingStep(forwarding);
	return {
		type: 'timeBased',
		id: forwarding.id,
		delay: forwardingStep.delay,
		name: forwarding.name,
		destination:
			forwardingStep.destination.type === 'ANNOUNCEMENT' && forwardingStep.destination.target === ''
				? defaultForwardingAnnouncement.destination
				: forwardingStep.destination,
		priority: forwarding.priority,
	};
}

export const useTimeBasedForwardingsOfOwner = (owner: string) => {
	const currentUser = useCurrentUser();
	const allForwardings = useSelector(state => state.forwardings);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchTimeBasedForwardings(owner));
	}, [owner, dispatch]);

	const getChannelTimeBasedForwardings = useCallback((): TimeBasedAcdForwarding[] => {
		const forwardings = allForwardings.items[owner];
		if (forwardings === undefined || forwardings.length === 0) {
			return [defaultForwardingAnnouncement];
		}

		return forwardings.map(forwarding => {
			return timeBasedForwardingSetToAcdForwarding(forwarding);
		});
	}, [allForwardings.items, owner]);

	const updateTimeBasedSets = async (forwardingSets: TimeBasedForwardingSet[]) => {
		const createSetAction = dispatch(updateTimeBasedSetsAction(owner, forwardingSets));

		if (didFetch(createSetAction)) {
			const result = await createSetAction.payload.promise;

			if (result.type !== 'TIME_BASED_SETS_UPDATE_SUCCESS') {
				return undefined;
			}
			return result.payload.items;
		}
		return undefined;
	};

	const updateTimeBasedForwardings = (forwardingSet: TimeBasedForwardingSet) => {
		dispatch(updateTimeBasedForwardingsAction(owner, forwardingSet));
	};

	const selectOrCreateAcdForwardingSet = async (forwardingId?: string) => {
		const acdForwardings = allForwardings.items[owner];
		if (!acdForwardings || acdForwardings.length === 0) {
			const newForwardingSet = await updateTimeBasedSets([
				{
					type: 'conditional',
					online: [],
					offline: [],
					busy: [],
					activeTimes: [fullWeek(currentUser?.timezone || 'Europe/Berlin')],
					isUserDefined: true,
					name: '',
					priority: 0,
				},
			]);

			if (!newForwardingSet) {
				return undefined;
			}
			return convertTimeBasedForwardingsSet(newForwardingSet[0], null);
		}
		return forwardingId
			? acdForwardings.find(forwarding => forwarding.id === forwardingId)
			: acdForwardings[0];
	};

	const updateChannelTimeBasedForwarding = async (
		destination: AnnouncementDestination | PhonenumberDestination | VoicemailDestination,
		delay: number,
		forwardingId?: string
	) => {
		const forwardingSet = await selectOrCreateAcdForwardingSet(forwardingId);

		if (!forwardingSet) {
			return;
		}

		let changedForwardingSet: TimeBasedForwardingSet;

		if (forwardingSet.priority === 1) {
			changedForwardingSet = {
				id: forwardingSet.id,
				name: forwardingSet.name,
				activeTimes: forwardingSet.activeTimes,
				isUserDefined: forwardingSet.isUserDefined,
				priority: forwardingSet.priority,
				type: 'unconditional',
				unconditional: [
					{
						delay,
						destination,
					},
				],
			};
		} else {
			changedForwardingSet = {
				id: forwardingSet.id,
				name: forwardingSet.name,
				activeTimes: forwardingSet.activeTimes,
				isUserDefined: forwardingSet.isUserDefined,
				priority: forwardingSet.priority,
				type: 'conditional',
				online: [
					{
						delay,
						destination,
					},
				],
				busy: [],
				offline: [],
			};
		}

		updateTimeBasedForwardings(changedForwardingSet);
	};

	return {
		timeBasedForwardings: allForwardings.items[owner],
		timeBasedForwardingsFetched: allForwardings.fetched[owner],
		getChannelTimeBasedForwardings,
		updateTimeBasedSets,
		updateTimeBasedForwardings,
		updateChannelTimeBasedForwarding,

		getAcdTimeBasedForwardings: getChannelTimeBasedForwardings,
		updateAcdTimeBasedForwarding: updateChannelTimeBasedForwarding,
	};
};
