import React from 'react';
import { Checkbox } from '@panda/ui';

import classes from './TypeFilter.scss';
import { Translate } from '../../../redux/slices/translations';
import { ChangeUrlParameter } from '../selections/types';
import { TypeType } from '../selections/parsers';
import { FilterDropdown } from './FilterDropdown';
import { Filter } from './Filter';
import auth from '../../../utils/authenticate/auth';

interface Props {
	open?: boolean;
	translate: Translate;
	onOpenClose?: (open: boolean) => void;

	selection: TypeType[];
	onChange: ChangeUrlParameter;
}

export class TypeFilter extends React.PureComponent<Props> {
	private onChangedType = (value: TypeType) => {
		const changed = Array.from(this.props.selection);
		if (this.hasSelected(value)) {
			changed.splice(changed.indexOf(value), 1);
			this.props.onChange({ type: changed, offset: 0 });
		} else {
			changed.push(value);
			this.props.onChange({ type: changed, offset: 0 });
		}
	};

	private hasSelected = (value: TypeType) => this.props.selection.includes(value);

	private isActive() {
		return this.props.selection.length !== 0;
	}

	private renderButtonLabel() {
		if (this.props.selection.length === 1) {
			if (this.props.selection[0] === 'fax') {
				return `${this.props.translate('FILTER_TYPE_FAX')}`;
			}

			if (this.props.selection[0] === 'sms') {
				return `${this.props.translate('FILTER_TYPE_SMS')}`;
			}

			if (this.props.selection[0] === 'call') {
				return `${this.props.translate('FILTER_TYPE_CALL')}`;
			}

			if (this.props.selection[0] === 'voicemail') {
				return `${this.props.translate('FILTER_TYPE_VOICEMAIL')}`;
			}

			throw new Error(
				'This error exists to make typescript happy. If it is thrown the internal state of TypeFilter broke.'
			);
		}

		if (this.props.selection.length > 1) {
			return `${this.props.translate('FILTER_TYPE')} (${this.props.selection.length})`;
		}

		return this.props.translate('FILTER_TYPE');
	}

	private renderContent() {
		return (
			<ul className={classes.filterlist}>
				{auth.isClassicPBXCustomer() && (
					<li>
						<Checkbox
							checked={this.hasSelected('call')}
							onChange={() => this.onChangedType('call')}
						>
							{this.props.translate('FILTER_TYPE_CALL')}
						</Checkbox>
					</li>
				)}
				<li>
					<Checkbox checked={this.hasSelected('fax')} onChange={() => this.onChangedType('fax')}>
						{this.props.translate('FILTER_TYPE_FAX')}
					</Checkbox>
				</li>
				<li>
					<Checkbox checked={this.hasSelected('sms')} onChange={() => this.onChangedType('sms')}>
						{this.props.translate('FILTER_TYPE_SMS')}
					</Checkbox>
				</li>
				{auth.isClassicPBXCustomer() && (
					<li>
						<Checkbox
							checked={this.hasSelected('voicemail')}
							onChange={() => this.onChangedType('voicemail')}
						>
							{this.props.translate('FILTER_TYPE_VOICEMAIL')}
						</Checkbox>
					</li>
				)}
			</ul>
		);
	}

	private renderDropdown() {
		if (!this.props.onOpenClose) {
			return null;
		}

		return (
			<FilterDropdown
				buttonLabel={this.renderButtonLabel()}
				active={this.isActive()}
				onOpenClose={this.props.onOpenClose}
				open={!!this.props.open}
			>
				{this.renderContent()}
			</FilterDropdown>
		);
	}

	public render() {
		if (this.props.onOpenClose) {
			return this.renderDropdown();
		}

		return (
			<Filter title={this.props.translate('FILTER_TYPE')}>
				<div className={classes.twoColumns}>{this.renderContent()}</div>
			</Filter>
		);
	}
}
