import React from 'react';
import classes from './WebphoneButton.scss';
import phoneIcon from '../../media/icons/icon-headset-light-line-default-24.svg';
import { Translate } from '../../redux/slices/translations';

interface Props {
	handleClick: () => void;
	translate: Translate;
}

export class WebphoneButton extends React.PureComponent<Props> {
	public render() {
		return (
			<div className={classes.WebphoneButtonContainer}>
				<button type="button" className={classes.WebphoneButton} onClick={this.props.handleClick}>
					<img src={phoneIcon} alt="" />
					<span className={classes.WebphoneButtonText}>
						{this.props.translate('WEBPHONE_HINT_TEXT')}
					</span>
				</button>
			</div>
		);
	}
}
