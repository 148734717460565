import { defineDialogs } from '../../dialog/Dialogs';

export const { withDialogs, useDialogs, Dialogs, DialogRedirect, DialogProvider } = defineDialogs(
	'/dialog',
	{
		activateCallRecorder: '/call-recorder/activate',
		deactivateCallRecorder: '/call-recorder/deactivate',
		smsSend: '/sms/:pseudoKey([A-Z0-9]+)?',
		addBlocklist: '/blocklist-add-entry',
		updateBlocklist: '/blocklist-update-entry/:phoneNumber',
		deleteBlocklist: '/blocklist-delete-entry/:phoneNumber',
		additionalExternalDeviceSettings: '/additional-device-settings/:webuserId/:deviceId(x\\d+)',
		additionalRegisterDeviceSettings: '/additional-device-settings/:webuserId/:deviceId(e\\d+)',
		localprefix: '/localprefix/:deviceId',
		incomingCallDisplay: '/incoming-call-display/:webuserId/:deviceId',
		deleteDevice: '/delete-device/:webuserId/:deviceId',
		deleteAdditionalSim: '/delete-additional-sim/:webuserId/:containerId/:mobileId',
		resetSipPassword: '/reset-sip-password/:deviceId',
		registeredDevices: '/registered-devices/:webuserId/:deviceId',
		faxSettings: '/fax-settings/:faxlineId',
		cashoutCommissions: '/cashoutDialog',
		editCashoutTarget: '/cashout-target',
		affiliateImpersonationFailed: '/affiliate-impersonation-failed',
		affiliateGrantFailed: '/affiliate-grant-failed',
		cashoutCommissionError: '/cashoutError',
		poolCallerId: '/pool-change-callerid-info/:webuserId/:deviceId',
		externalDeviceSettings: '/external-device-settings/:webuserId/:deviceId',
		externalDeviceTargetNumber: '/external-device-target-number/:webuserId/:deviceId',
		voicemailAlias: '/voicemail-alias/:voicemailId',
		voicemailPin: '/voicemail-pin/:userId/:voicemailId',
		voicemailAuthorizedNumbers: '/voicemail-authorized-numbers/:webuserId/:voicemailId',
		voicemailTranscription: '/voicemail-transcription/:voicemailId',
		voicemailActiveAudioFile: '/active-greeting/:voicemailId',
		createUnlinked: '/create-unlinked/:webuserId',
		faxReport: '/fax-report/:webuserId/:faxlineId',
		addContact: '/add-contact/:pseudoKey?',
		addNumberToContact: '/add-phonenumber-to-contact/:pseudoKey?',
		deleteFaxline: '/delete-faxline/:faxlineId',
		sendFax: '/new-fax/:webuserId',
		contactsImport: '/contacts-import',
		editSimAlias: '/sim-alias/:webuserId/:containerId/:mobileId',
		changeSim: '/change-sim/:webuserId/:containerId/:mobileId',
		orderSim: '/order-sim',
		createMobileDevice: '/create-mobile/:webuserId/:containerId/:intendedUse(VOICE|DATA)',
		addDevice:
			'/add-device/:webuserId/:routingTarget([gp]\\d+)?/:initialDeviceType(REGISTER|MOBILE)?',
		clickToDial: '/new-click-to-dial/:webuserId/:pseudoKey?',
		clickToPlay: '/click-to-play/:webuserId/:datadId',
		clickToRecord: '/click-to-record/:webuserId/:voicemailId',
		clickToCallThrough: '/click-to-record-callthrough/:webuserId/:callthroughId',
		clickToRecordIvr: '/click-to-record-ivr/:webuserId/:ivrId/:ivrEndpoint',
		editIvrKeyForwarding: '/edit-ivr/:ivrId/key-forwarding/:forwardingKey',
		audioFiles: '/audioFiles/:ownerId/:voicemailId',
		assignHardwareUser: '/hardware-user-select/:macAddress',
		addPersonalAccessToken: '/create-token/:webuserId',
		unverified: '/unverified',
		deleteLogo: '/delete-logo',
		uploadLogo: '/upload-logo',
		notEnoughRightsToCreateAddress: '/not-enough-rights-to-create-address',
		addressVerificationLetterSent: '/address-verification-letter-sent',
		addDirectDial: '/directDial/:endpointId',
		editDirectDial: '/edit-directdial/:endpointId/:currentDirectDial',
		deleteRouting: '/delete-routing/:webuserId/:phonelineId',
		addPhoneProvisioning: '/add-phone-provisioning',
		deletePhoneProvisioning: '/delete-phone-provisioning/:macAddress',
		deleteAllContacts: '/delete-all-contacts',
		cancelContactImport: '/cancel-contact-import',
		deleteAllContactsError: '/delete-all-contacts-error',
		editContactError: '/edit-contact-error',
		deleteContact: '/delete-contact/:contactId',
		contactImportSuccess: '/contactImportSuccess/:contactsImported',
		contactImportFailure: '/contactImportFailure',
		createContact: '/create-contact/:numberPseudo?',
		editContactField: '/edit-contact-field/:contactId/:fieldName/:fieldIndex?',
		exportContacts: '/export-contacts',
		exportContactsAsCSV: '/export-contacts-as-csv',
		exportContactsAsVCard: '/export-contacts-as-vcard',
		simDeactivateVoice: '/sim-deactivate-voice/:deviceId/:simId',
		deleteTrunk: '/delete-trunk/:trunkId',
		deleteUser: '/delete-user/:userId',
		renameUser: '/rename-user/:userId',
		userEmail: '/user-email/:userId',
		userEmailChangeRequest: '/revoke-user-email-change/:userId',
		userLanguage: '/user-language/:userId',
		userLocation: '/user-location/:userId',
		userPasswordReset: '/user-password-reset/:userId',
		dataUsageSettings: '/data-usage/:containerId',
		dataUsageEmptyState: '/data-usage-info',
		deletePersonalAccessToken: '/delete-token-confirm/:tokenId',
		blockAnonymous: '/block-anonymous/:webuserId/:phonelineId',
		scopeList: '/scope-list/:webuserId/:tokenId',
		addNumbersToEndpoint: '/numbers-add/:endpointId',
		activateNumber: '/number-activate/:contextId/:endpointId/:pseudoKey([A-Z0-9]+)',
		deactivateNumber: '/number-deactivate/:contextId/:pseudoKey([A-Z0-9]+)',
		webphoneNotifications: '/webphone-notification',
		twoFactorAuthentication: '/two-factor-authentication',
		dnsChallenge: '/dns-challenge',
		verifyAccount: '/verify-account',
		editForwarding:
			'/edit-forwarding/:type(online|offline|busy|unconditional)/:webuserId/:endpointId/:setId/:stepIndex',
		addForwarding:
			'/add-forwarding/:type(online|offline|busy|unconditional)/:webuserId/:endpointId/:setId([0-9]*)?',
		cancelAccount: '/account/cancel',
		revokeAccountCancellation: '/account/revoke-cancellation',
		transcribeSingleVoicemail: '/transcribe-single-voicemail/:eventId/:voicemailId?/',
		activeTimes: '/activetimes/:webuserId/:type(extension|acd)/:endpointId/',
		additionalNumbers: '/additional-numbers/:contextId/:endpointId/:mode(view|edit)/',
		addDevicePoolNumbersToEndpoint: '/device-pool-numbers-add/:endpointId',
		editDevicePoolNumbersToEndpoint: '/device-pool-numbers-edit/:endpointId/:pseudoKey([A-Z0-9]+)',
		unrouteDevicePoolNumbersFromEndpoint:
			'/device-pool-numbers-unroute/:endpointId/:pseudoKey([A-Z0-9]+)',
		dataAutomation: '/data-automation/:webuserId/:deviceId',
		dataUpgrade: '/data-upgrade/:deviceId/',
		bookMobile: '/book-mobile/:webuserId',
		createIvr: '/create-ivr',
		chooseIvrAnnouncement: '/choose-announcement/:extensionId',
		assignIvrPhoneNumber: '/edit-ivr/:ivrId/phonenumber',
		chooseCreateMobileDeviceOrOrderSim: '/create-mobile-or-order-sim/:webuserId/:containerId',
		businessVerification: '/business-verification/:addressId',
		domainVerification: '/domain-verification',
		deleteLastDomainVerification: '/domain-verification/:domainId/delete',
		ssoConfiguration: '/sso-configuration',
		verificationRequired: '/verification-required/:addressId',
		businessVerificationSucceeded: '/business-verification-succeeded',
		businessVerificationFailed: '/business-verification-failed/:addressId/:reason',
		satelliteEnterpriseSupport: '/satellite-enterprise-support',
		setGroupAlias: '/group-alias/:groupId',
		deleteGroup: '/delete-group/:groupId',
		createGroup: '/create-group',
		trunkCallerId: '/trunk-caller-id/:trunkId',
		trunkEmergencyLocation: '/trunk-location/:trunkId',
		renameTrunk: '/rename-trunk/:trunkId',
		renameConferenceRoom: '/rename-conferenceroom/:conferenceRoomId',
		cancelConferenceRoom: '/cancel-conferenceroom/:conferenceRoomId',
		bookConferenceRoom: '/book-conferenceroom',
		revokeConferenceRoomCancellation: '/revoke-conferenceroom-cancellation/:conferenceRoomId',
		generateTrunkCredentialsPassword: '/generate-trunk-password/:trunkId',
		groupMembers: '/group-members/:groupId',
		greetingAudioFiles: '/greeting/audiofiles/:groupId/:mode(create|edit)',
		addConferenceRoomGreeting: '/conference-greeting/audiofiles/:conferenceRoomId',
		callQueueAudioFiles: '/call-queue/audiofiles/:groupId/:mode(create|edit)',
		deleteGroupGreeting: '/delete-group-greeting/:groupId',
		deleteConferenceRoomGreeting: '/delete-conference-greeting/:conferenceRoomId',
		verifyAddressByLetter: '/verify-address-by-letter',
		trunkRouting: '/trunk-routing/:trunkId',
		deleteDevicePool: '/delete-device-pool/:webuserId/:poolId',
		devicePoolSmsSim: '/sms-sim/:webuserId/:poolId',
		deviceAlias: '/device-alias/:webuserId/:deviceId',
		groupStatisticsTeaser: '/group-statistics-teaser',
		groupStatisticsSurvey: '/group-statistics-survey',
		bookGroupStatistics: '/groups/book-statistics',
		bookGroupStatisticsSingleGroup: '/groups/book-statistics-single/:groupId',
		bookTrunk: '/book-trunk',
		bookTrunkContingent: '/book-trunk-contingent/:trunkId',
		changeTrunk: '/change-trunk/:trunkId',
		changeTrunkContingent: '/change-trunk-contingent/:trunkId/:contractId',
		revokeTrunkContingentCancellation: '/revoke-trunk-contingent/:trunkId/:contractId',
		cancelTrunkContract: '/cancel-trunk/:trunkId',
		revokeTrunkContractCancellation: '/revoke-trunk/:trunkId',
		bookApp: '/book-app/:webuserId',
		bookAppBulk: '/book-app-bulk',
		cancelApp: '/cancel-app/:webuserId',
		revokeCancelApp: '/revoke-app-cancellation/:webuserId',
		downloadApp: '/download-app',
		upgradeApp: '/upgrade-app/:webuserId',
		bookAppAdminRequired: '/book-app-admin-required',
		unlinkedDeviceCallerId: '/unlinked-caller-id/:deviceId',
		deviceCallerId: '/caller-id/:webuserId/:deviceId',
		emergencyLocation: '/emergency-location/:webuserId/:deviceId',
		emergencyLocationWarning: '/emergency-location-warning',
		appAdminAdvertisement: '/app-admin-advertisement',
		createHolidayForwarding: '/create-holiday-forwarding/:endpointId',
		editHolidayForwarding: '/edit-holiday-forwarding/:endpointId/:dateBasedForwardingId',
		editDateBasedProfiles: '/edit-holiday-profiles/:endpointId/:dateBasedType(HOLIDAY|CUSTOM)',
		editAcdDateBasedProfiles: '/edit-channel-date-based-profiles/:endpointId',
		editDateBasedForwardingDestination:
			'/edit-date-based-forwarding-destination/:endpointId/:dateBasedSetId',
		createCustomDatesForwarding: '/create-custom-dates-forwarding/:endpointId',
		editCustomDatesForwarding: '/edit-custom-dates-forwarding/:endpointId/:dateBasedForwardingId',
		createAcd: '/create-channel',
		createAcdDateBasedForwarding: '/create-channel-date-based-forwarding/:endpointId',
		setAcdName: '/channels/:acdId/name',
		setAcdNumbers: '/channels/:acdId/phoneNumbers',
		setAcdAgents: '/channels/:acdId/agents',
		exportAcdData: '/channels/:acdId/export',
		deleteAcd: '/channels/:acdId/delete',
		addNumbersToAcd: '/channels/:acdId/phoneNumbers',
		addAgentsToAcd: '/channels/:acdId/agents',
		manageAcdQueueMaxTime: '/channels/:acdId/settings/queue/maxTime',
		managePersonalChannelQueueMaxTime: '/my-channels/:channelId/settings/queue/maxTime',
		manageAcdFollowUpTime: '/channels/:acdId/settings/agents/followUp',
		manageAcdRingTime: '/channels/:acdId/settings/agents/ringTime',
		manageAcdAudioFiles:
			'/channels/:acdId/settings/queue/audiofiles/:audioFileType(callqueue|greeting)',
		manageAcdRingingSettings: '/channels/:acdId/settings/ringing',
		acdForwarding:
			'/channels/:acdId/:forwardingSetType(timeBased|dateBased)/forwardingSets/:forwardingId?/forwarding/',
		setAcdStatisticsAvailabilityTarget: '/channels/:acdId/statistics/targets/availablitity',
		setAcdStatisticsCallPercentageInTimeLimitTarget:
			'/channels/:acdId/statistics/targets/callPercentageInTimeLimit',
		setAcdStatisticsAverageHandlingTimeTarget:
			'/channels/:acdId/statistics/targets/averageHandlingTime',
		activateAgentStatistics: '/channels/:acdId/statistics/agentStatistics/activate',
		manageAcdTeamLeads: '/channels/:acdId/teamLeads',
		createLocation: '/create-location',
		createDeLocation: '/create-de-location',
		editLocation: '/edit-location/:addressId',
		moveLocation: '/move-location/:addressId',
		deleteLocation: '/delete-location/:addressId',
		userAvatarUpload: '/user-avatar-upload/:webuserId',
		contactAvatarUpload: '/contact-avatar-upload/:contactId',
		createSingleUser: '/create-user',
		createSingleOrBatchUser: '/create-users',
		importBatchUserByProvider: '/batch-import-user/:provider/callback',
		createUserRouting: '/create-user/:webuserId/routing',
		batchImportUserExecutionSucceeded: '/batch-import-user-execution-succeeded',
		batchImportUserExecutionFailed: '/batch-import-user-execution-failed',
		batchImportUserExecutionRunning: '/batch-import-user-execution-running/:jobId',
		batchImportUser: '/batch-import-user',
		batchImportExecuteJob: '/batch-import-execute-job',
		batchImportCancelJob: '/batch-import-cancel-job',
		batchImportDeleteLastRemainingUser: '/batch-import-delete-last-remaining-user',
		phonebook: '/phonebook/:addressId',
		transferAudioFiles: '/transferAudio',
		eventlistLifetime: '/eventlistLifetime',
		callRecordingAudioFiles: '/call-recording',
		renameReport: '/reports/:reportId/edit',
		addReport: '/create-report',
		createUserNotifications: '/create-user-notifications',
		replaceUserNotifications: '/edit-user-notifications/:notificationId',
		deleteUserNotification: '/user-notifications/:notificationId/delete',
		bookSipgateio: '/book-sipgateio',
		cancelSipgateio: '/cancel-sipgateio',
		revokeSipgateioCancellation: '/revoke-sipgateio',
		editIncomingWebhookUrl: '/incoming-webhook-url',
		editOutgoingWebhookUrl: '/outgoing-webhook-url',
		editWebhookSources: '/webhook-sources',
		selectPaymentMethod: '/select-payment',
		singlePhonenumberRouting: '/number-routing/:pseudoKey([A-Z0-9]+)',
		deletePhonenumberRouting: '/delete-number-routing/:pseudoKey([A-Z0-9]+)',
		deleteMultiplePhonenumberRoutings:
			'/delete-number-routings/:pseudoKey([A-Z0-9]+)/:parentPseudoKey([A-Z0-9]+)?',
		blockPhonenumberRouting:
			'/block-number-routing/:pseudoKey([A-Z0-9]+)/:parentPseudoKey([A-Z0-9]+)?',
		releaseMobilePhonenumberForPorting:
			'/release-mobile-number-for-porting/:pseudoKey([A-Z0-9]+)/:type(single|multiple)/:parentPseudoKey([A-Z0-9]+)?',
		cancelPhonenumberContract:
			'/cancel-number-contract/:pseudoKey([A-Z0-9]+)/:type(single|multiple)',
		revokePhonenumberContract:
			'/revoke-number-contract/:pseudoKey([A-Z0-9]+)/:type(single|multiple)',
		createApiClient: '/create-api-client',
		deleteApiClient: '/delete-api-client/:clientId',
		createRedirectUri: '/create-redirect-uri/:clientId',
		editRedirectUri: '/edit-redirect-uri/:clientId/:redirectUri',
		deleteRedirectUri: '/delete-redirect-uri/:clientId/:redirectUri',
		createWebOrigin: '/create-web-origin/:clientId',
		editWebOrigin: '/edit-web-origin/:clientId/:webOrigin',
		deleteWebOrigin: '/delete-web-origin/:clientId/:webOrigin',
		editApiClient: '/edit-api-client/:clientId',
		autoCrediting: '/auto-crediting',
		managePaymentMethods: '/manage-payment-methods',
		singlePayment: '/singlePayment',
		bookPhonenumbers: '/book-phonenumbers',
		mobileContingent: '/mobile-contingent/:deviceId',
		mobileCallPackages: '/mobile-call-packages/:deviceId',
		mobileDataOverview: '/mobile-data/:userId/:deviceId',
		devicePoolSettings: '/pool-settings/:webuserId/:deviceId',
		bookBaseContract: '/book/:optionId',
		cancelBaseContract: '/cancel/:currentOptionId',
		revokeBaseContractCancellation: '/revoke-cancellation/:currentOptionId',
		revokeBaseContractDownngrade: '/revoke-downgrade/:currentOptionId',
		changeBillingAddress: '/changeBillingAddress',
		changeVatId: '/changeVatId',
		recreateInvoice: '/recreateInvoice/:invoiceId',
		changeInvoiceEmail: '/changeInvoiceEmail',
		changeItemizedBillingSettings: '/changeItemizedBillingSettings',
		setIVRAlias: '/ivr-alias/:ivrId',
		deleteIVR: '/delete-ivr/:ivrId',
		channelRoutingPhonenumbers: '/channel-routing-phonenumbers/:channelId',
		faxRoutingPhonenumbers: '/fax-routing-phonenumbers/:faxId',
	}
);

export type WithDialogProps = {
	dialogs: ReturnType<typeof useDialogs>;
};
