import { ApiGroup } from '../../../api/types/groups';
import { Faxline } from '../../../api/types/faxlines';

export type OperationState = 'idle' | 'pending' | 'succeeded' | 'failed';

export type FaxGroup = Omit<ApiGroup, 'greeting' | 'callQueue' | 'statisticsBooked'> & {
	faxLine: Faxline;
};

export type FaxGroupState = {
	items: FaxGroup[];
	fetching: OperationState;
	lastError?: FaxGroupError;
};

interface ErrorBase<T extends string> {
	name: T;
	message: string;
}

export type FaxGroupError = ErrorBase<'COULD_NOT_FETCH_FAX_GROUPS'>;

export const CouldNotFetchFaxGroups = (): FaxGroupError => ({
	name: 'COULD_NOT_FETCH_FAX_GROUPS',
	message: 'Could not fetch fax groups',
});
