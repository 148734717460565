import { getCountryCodeFromLocale } from '.';

const LOCALE_STORAGE_KEY = 'app-locale';

/**
 * @deprecated
 *
 * This is a horrible crutch to avoid passing locale all over the place.
 * Please get rid of this by making locale-passing explicit!
 */
export const saveLocale = (locale: string) =>
	localStorage.setItem(LOCALE_STORAGE_KEY, JSON.stringify(locale));

/**
 * @deprecated
 *
 * This is a horrible crutch to avoid passing locale all over the place.
 * Please get rid of this by making locale-passing explicit!
 */
export const getLocale = () => {
	const token = localStorage.getItem(LOCALE_STORAGE_KEY);
	return token ? JSON.parse(token) : 'de_DE';
};

/**
 * @deprecated
 *
 * This is a horrible crutch to avoid passing locale all over the place.
 * Please get rid of this by making locale-passing explicit!
 *
 * You can use `getCountryCodeFromLocale` to do the same thing with an
 * explicitly passed locale.
 */
export function getCurrentCountryCode() {
	return getCountryCodeFromLocale(getLocale());
}
