import decodeJsonWebToken from 'jwt-decode';

export type AuthenticationType =
	| 'MAGIC_LINK'
	| 'DEFAULT'
	| 'IMPERSONATION'
	| 'AFFILIATE_IMPERSONATION';

interface JwtToken {
	/** Token identifier */
	jti: string;

	/** Token issuance time */
	iat: number;
	/** Expiration time */
	exp: number;

	/** Uniquer user identifier */
	sub: string;

	/** Type of authentication */
	authenticationType: AuthenticationType;

	featureScope: 'CLASSIC_PBX' | 'NEO_PBX';
	email: string;
}

export const decodeToken = (token: string) => {
	const decodedToken = decodeJsonWebToken(token);

	if (typeof decodedToken !== 'object' || decodedToken === null) {
		throw new Error('Received invalid webtoken: not an object');
	}

	if (!('jti' in decodedToken)) {
		throw new Error('Received invalid webtoken: missing jti');
	}

	if (!('iat' in decodedToken)) {
		throw new Error('Received invalid webtoken: missing iat');
	}

	if (!('exp' in decodedToken)) {
		throw new Error('Received invalid webtoken: missing exp');
	}

	if (!('sub' in decodedToken)) {
		throw new Error('Received invalid webtoken: missing sub');
	}

	return decodedToken as JwtToken;
};
