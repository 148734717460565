import React from 'react';
import { Button, Headline, ManagedInput } from '@panda/ui';
import { ManagedMultiFieldValidator } from '@web-apps/forms';
import classes from './EmptyLabelSlideInContent.scss';
import { Translate } from '../../../redux/slices/translations';
import EmptyLabelIllustration from '../../../components/images/illustrations/sipgate_team_eventlist_Lable.svg';
import { ManagedForm } from '../../../components/forms/ManagedForm';
import auth from '../../../utils/authenticate/auth';

interface Props {
	translate: Translate;

	createLabel: (name: string) => void;
	validateLabel: (translate: Translate) => ManagedMultiFieldValidator<string, unknown, string>;
}

export class EmptyLabelSlideInContent extends React.PureComponent<Props> {
	public render() {
		return (
			<ManagedForm
				initialValues={{
					name: '',
				}}
				onSubmit={(data: { name: string }) => {
					this.props.createLabel(data.name);
				}}
				validators={{
					name: this.props.validateLabel(this.props.translate),
				}}
			>
				{({ form, fields }) => (
					<>
						<ManagedInput
							managedField={fields.name}
							label={this.props.translate('CREATE_LABEL_TITLE')}
							placeholder={this.props.translate(
								auth.isClassicPBXCustomer()
									? 'CREATE_FIRST_LABEL_PLACEHOLDER'
									: 'NEO_CREATE_FIRST_LABEL_PLACEHOLDER'
							)}
							// eslint-disable-next-line jsx-a11y/no-autofocus
							autoFocus
						/>
						<div className={classes.createLabelButtonWrapper}>
							<Button action="confirm" type="submit" disabled={!form.canBeSubmitted}>
								{this.props.translate('CREATE_LABEL_SAVE')}
							</Button>
						</div>
						<div className={classes.emptyState}>
							<figure className={classes.illustrationContainer}>
								<img src={EmptyLabelIllustration} height="100%" alt="" />
							</figure>
							<Headline className={classes.headline}>
								{this.props.translate('CREATE_FIRST_LABEL_HEADLINE')}
							</Headline>
							<p>{this.props.translate('CREATE_FIRST_LABEL_TEXT')}</p>
						</div>
					</>
				)}
			</ManagedForm>
		);
	}
}
