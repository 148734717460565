import { createSlice } from '@reduxjs/toolkit';
import { FaxGroupState } from './types';
import { fetchFaxGroups } from './actions';

export const initialState: FaxGroupState = {
	items: [],
	fetching: 'idle',
};

const slice = createSlice({
	name: 'faxGroups',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchFaxGroups.pending, state => ({
			...state,
			fetching: 'pending',
		}));

		builder.addCase(fetchFaxGroups.fulfilled, (_state, { payload }) => ({
			items: payload,
			fetching: 'succeeded',
		}));

		builder.addCase(fetchFaxGroups.rejected, (state, { payload: lastError }) => ({
			...state,
			fetching: 'failed',
			lastError,
		}));
	},
});

export const reducer = slice.reducer;
