/* eslint-disable no-param-reassign */
import { DateTime } from 'luxon';
import { createSlice, UnknownAction } from '@reduxjs/toolkit';
import {
	cancelContract,
	deleteRoutings,
	fetchPhonenumbers,
	revokeContractCancellation,
	setChannelRoutings,
	setRoutings,
} from './actions';
import {
	GermanLandlineNumber,
	GermanLandlineNumberBlock,
	GermanLandlineProlongationChild,
	GermanLandlineProlongationParent,
	GermanMobileBlock,
	GermanMobileNumber,
	GermanPreviewNumber,
	InternationalNumber,
	isPhonenumberWithAddress,
	isPhonenumberWithChildren,
	Phonenumber,
	PhonenumberCancellation,
	PhonenumberContract,
	PhonenumbersState,
	Porting,
	UKLandlineNumber,
	UKLandlineNumberGroup,
	UKNonGeographicNumber,
	UnknownNumber,
	UnknownNumberBlock,
	UnknownProlongationParent,
} from './types';
import {
	ApiPhonenumber,
	ApiPhonenumberContract,
	ApiPhonenumberRouting,
	ApiPorting,
} from '../../../api/types/phonenumbers';
import { renameTrunk } from '../../modules/trunks';
import { handleActions } from '../../utils/actions';
import { renameConferenceRoom } from '../conferenceRoom';
import { createAcd, deleteAcd, setAcdName } from '../acds';
import { setPhonelineAlias } from '../../modules/phonelines';
import { setFaxlineAlias } from '../../modules/faxlines';
import { setGroupAlias } from '../../modules/groups';
import { setAlias } from '../../modules/devices';
import { moveAddress } from '../../modules/addresses';

const mapApiContractToContract = (apiContract: ApiPhonenumberContract): PhonenumberContract => {
	return {
		contractId: apiContract.contractId,
		nextCancellationDate: apiContract.nextCancellationDate
			? DateTime.fromISO(apiContract.nextCancellationDate)
			: null,
		cancellation: apiContract.cancellation
			? {
					terminationDate: DateTime.fromISO(apiContract.cancellation.terminationDate),
					revocable: apiContract.cancellation.revocable,
				}
			: null,
		product: {
			productId: apiContract.product.productId,
			productName: apiContract.product.productName,
			cost: {
				amount: {
					amount: apiContract.product.recurringFee.amountInTenThousandths,
					currency: apiContract.product.recurringFee.currency,
					fraction: 10_000,
				},
				isNetto: apiContract.product.recurringFee.net,
				interval: 'monthly',
			},
		},
	};
};

const mapApiPortingToPorting = (apiPorting: ApiPorting | null): Porting | null => {
	if (!apiPorting) return null;

	if (apiPorting.state === 'UNKNOWN') {
		return apiPorting;
	}

	if (apiPorting.state === 'OPT_IN') {
		return {
			...apiPorting,
			optInUntil: DateTime.fromISO(apiPorting.optInUntil),
		};
	}

	return {
		...apiPorting,
		date: DateTime.fromISO(apiPorting.date),
	};
};

const mapApiPhonenumberToPhonenumber = (apiNumber: ApiPhonenumber): Phonenumber => {
	const baseProperties = {
		e164Number: apiNumber.e164Number,
		routing: apiNumber.routing,
		contract: apiNumber.contract ? mapApiContractToContract(apiNumber.contract) : null,
	};

	switch (apiNumber.type) {
		case 'GERMAN_LANDLINE_PREVIEW':
			return {
				...baseProperties,
				type: apiNumber.type,
			} satisfies GermanPreviewNumber;
		case 'GERMAN_LANDLINE':
			return {
				...baseProperties,
				type: apiNumber.type,
				addressId: apiNumber.addressId,
				porting: mapApiPortingToPorting(apiNumber.porting),
			} satisfies GermanLandlineNumber;
		case 'GERMAN_LANDLINE_BLOCK':
			return {
				...baseProperties,
				type: apiNumber.type,
				numbers: apiNumber.numbers.map(
					n =>
						mapApiPhonenumberToPhonenumber(n) as
							| GermanLandlineNumber
							| GermanLandlineProlongationParent
				),
			} satisfies GermanLandlineNumberBlock;
		case 'GERMAN_LANDLINE_PROLONGATION_PARENT':
			return {
				...baseProperties,
				type: apiNumber.type,
				porting: mapApiPortingToPorting(apiNumber.porting),
				addressId: apiNumber.addressId,
				baseContract: apiNumber.baseContract
					? mapApiContractToContract(apiNumber.baseContract)
					: null,
				numbers: apiNumber.numbers.map(
					n => mapApiPhonenumberToPhonenumber(n) as GermanLandlineProlongationChild
				),
			} satisfies GermanLandlineProlongationParent;
		case 'GERMAN_LANDLINE_PROLONGATION_CHILD':
			return {
				...baseProperties,
				type: apiNumber.type,
				contract: null,
				addressId: apiNumber.addressId,
			} satisfies GermanLandlineProlongationChild;
		case 'GERMAN_MOBILE':
			return {
				...baseProperties,
				type: apiNumber.type,
				porting: mapApiPortingToPorting(apiNumber.porting),
			} satisfies GermanMobileNumber;
		case 'GERMAN_MOBILE_BLOCK':
			return {
				...baseProperties,
				type: apiNumber.type,
				numbers: apiNumber.numbers.map(
					n => mapApiPhonenumberToPhonenumber(n) as GermanMobileNumber
				),
			} satisfies GermanMobileBlock;
		case 'UK_LANDLINE':
		case 'UK_NON_GEOGRAPHIC':
			return {
				...baseProperties,
				type: apiNumber.type,
			} satisfies UKLandlineNumber | UKNonGeographicNumber;
		case 'UK_LANDLINE_GROUP':
			return {
				...baseProperties,
				type: apiNumber.type,
				numbers: apiNumber.numbers.map(n => mapApiPhonenumberToPhonenumber(n) as UKLandlineNumber),
			} satisfies UKLandlineNumberGroup;
		case 'INTERNATIONAL':
			return {
				...baseProperties,
				type: apiNumber.type,
			} satisfies InternationalNumber;
		case 'UNKNOWN':
			return {
				...baseProperties,
				type: apiNumber.type,
			} satisfies UnknownNumber;
		case 'UNKNOWN_BLOCK':
			return {
				...baseProperties,
				type: apiNumber.type,
				numbers: apiNumber.numbers.map(
					n =>
						mapApiPhonenumberToPhonenumber(n) as
							| GermanLandlineNumber
							| GermanLandlineProlongationParent
							| GermanMobileNumber
							| InternationalNumber
							| UKLandlineNumber
							| UnknownNumber
				),
			} satisfies UnknownNumberBlock;
		case 'UNKNOWN_PROLONGATION':
			return {
				...baseProperties,
				type: apiNumber.type,
				numbers: apiNumber.numbers.map(
					n => mapApiPhonenumberToPhonenumber(n) as UnknownNumber | GermanLandlineProlongationChild
				),
			} satisfies UnknownProlongationParent;
	}
};

const updateRouting = (
	phonenumbers: Phonenumber[],
	changedE164Numbers: string[],
	newRouting: ApiPhonenumberRouting | null
) => {
	phonenumbers.forEach(phonenumber => {
		if (changedE164Numbers.includes(phonenumber.e164Number)) {
			phonenumber.routing = newRouting;
		}

		if (isPhonenumberWithChildren(phonenumber)) {
			updateRouting(phonenumber.numbers, changedE164Numbers, newRouting);
		}
	});
};

const updateContractCancellation = (
	phonenumbers: Phonenumber[],
	changedContractId: string,
	newCancellation: PhonenumberCancellation | null
) => {
	phonenumbers.forEach(phonenumber => {
		if (
			phonenumber.type === 'GERMAN_LANDLINE_PROLONGATION_PARENT' &&
			phonenumber.contract &&
			phonenumber.baseContract &&
			changedContractId === phonenumber.baseContract.contractId
		) {
			phonenumber.baseContract.cancellation = newCancellation;
			phonenumber.contract.cancellation = newCancellation;
		}

		if (changedContractId === phonenumber.contract?.contractId) {
			phonenumber.contract.cancellation = newCancellation;

			if (isPhonenumberWithChildren(phonenumber)) {
				phonenumber.numbers.forEach(childPhonenumber => {
					if (childPhonenumber.contract) {
						childPhonenumber.contract.cancellation = newCancellation;
					}
				});
			}
		}

		if (isPhonenumberWithChildren(phonenumber)) {
			updateContractCancellation(phonenumber.numbers, changedContractId, newCancellation);
		}
	});
};

const updateAliasForRouting = (
	phonenumbers: Phonenumber[],
	extensionId: string,
	newAlias: string
): Phonenumber[] => {
	return phonenumbers.map(phonenumber => {
		if (phonenumber.routing && phonenumber.routing.targetId === extensionId) {
			if (phonenumber.type === 'GERMAN_LANDLINE_PROLONGATION_PARENT') {
				return {
					...phonenumber,
					routing: {
						...phonenumber.routing,
						displayAlias: newAlias,
					},
					numbers: updateAliasForRouting(phonenumber.numbers, extensionId, newAlias),
				} as Phonenumber;
			}

			return {
				...phonenumber,
				routing: {
					...phonenumber.routing,
					displayAlias: newAlias,
				},
			} as Phonenumber;
		}

		if (isPhonenumberWithChildren(phonenumber)) {
			return {
				...phonenumber,
				numbers: updateAliasForRouting(phonenumber.numbers, extensionId, newAlias),
			} as Phonenumber;
		}

		return phonenumber;
	});
};

const updateAddressId = (
	phonenumbers: Phonenumber[],
	oldAddressId: number,
	newAddressId: number
): Phonenumber[] => {
	return phonenumbers.map(phonenumber => {
		if (
			isPhonenumberWithAddress(phonenumber) &&
			phonenumber.addressId === oldAddressId.toString()
		) {
			if (isPhonenumberWithChildren(phonenumber)) {
				return {
					...phonenumber,
					addressId: newAddressId.toString(),
					numbers: updateAddressId(phonenumber.numbers, oldAddressId, newAddressId),
				} as Phonenumber;
			}

			return {
				...phonenumber,
				addressId: newAddressId.toString(),
			};
		}

		if (isPhonenumberWithChildren(phonenumber)) {
			return {
				...phonenumber,
				numbers: updateAddressId(phonenumber.numbers, oldAddressId, newAddressId),
			} as Phonenumber;
		}

		return phonenumber;
	});
};

const initialState: PhonenumbersState = {
	fetched: false,
	fetching: false,
	items: [],
};

const sortByE164Number = (a: Phonenumber, b: Phonenumber) =>
	a.e164Number.localeCompare(b.e164Number);

const rtkReducer = createSlice({
	name: 'phonenumbers',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchPhonenumbers.pending, state => {
			return { ...state, fetching: true };
		});
		builder.addCase(fetchPhonenumbers.fulfilled, (state, action) => {
			const allNumbers = action.payload.map(mapApiPhonenumberToPhonenumber).map(number => {
				if ('numbers' in number) {
					number.numbers.sort(sortByE164Number);
				}

				return number;
			});

			const allExceptInternational = allNumbers.filter(n => n.type !== 'INTERNATIONAL');
			const international = allNumbers.filter(n => n.type === 'INTERNATIONAL');

			return {
				...state,
				fetched: true,
				fetching: false,
				items: [
					...allExceptInternational.sort(sortByE164Number),
					...international.sort(sortByE164Number),
				],
			};
		});
		builder.addCase(setRoutings.fulfilled, (state, action) => {
			updateRouting(state.items, action.meta.arg.e164Numbers, action.payload);
		});
		builder.addCase(setChannelRoutings.fulfilled, (state, action) => {
			updateRouting(state.items, action.meta.arg.e164Numbers, action.payload);
		});
		builder.addCase(deleteRoutings.pending, (state, action) => {
			updateRouting(state.items, action.meta.arg.e164Numbers, null);
		});
		builder.addCase(cancelContract.fulfilled, (state, action) => {
			updateContractCancellation(state.items, action.meta.arg.contractId, {
				terminationDate: DateTime.fromISO(action.payload.terminationDate),
				revocable: action.payload.revocable,
			});
		});
		builder.addCase(revokeContractCancellation.pending, (state, action) => {
			updateContractCancellation(state.items, action.meta.arg.contractId, null);
		});
		builder.addCase(renameConferenceRoom.pending, (state, action) => {
			return {
				...state,
				items: updateAliasForRouting([...state.items], action.meta.arg.id, action.meta.arg.alias),
			};
		});
		builder.addCase(setAcdName.pending, (state, action) => {
			return {
				...state,
				items: updateAliasForRouting([...state.items], action.meta.arg.acdId, action.meta.arg.name),
			};
		});
		builder.addCase(createAcd.fulfilled, (state, action) => {
			updateRouting(state.items, action.payload.phoneNumbers, {
				targetId: action.payload.id,
				targetType: 'ACD',
				displayAlias: action.payload.name,
			});
		});
		builder.addCase(deleteAcd.pending, (state, action) => {
			const affectedNumbers = state.items
				.filter(number => number.routing?.targetId === action.meta.arg.acdId)
				.map(number => number.e164Number);
			updateRouting(state.items, affectedNumbers, null);
		});
	},
}).reducer;

const legacyReducer = handleActions<
	PhonenumbersState,
	PossibleActions<
		| typeof renameTrunk
		| typeof setPhonelineAlias
		| typeof setFaxlineAlias
		| typeof setGroupAlias
		| typeof setAlias
		| typeof moveAddress
	>
>(
	{
		TRUNKS_RENAME_PENDING: (state, { data }) => {
			return {
				...state,
				items: updateAliasForRouting([...state.items] as Phonenumber[], data.id, data.alias),
			};
		},
		PHONELINE_SET_ALIAS_PENDING: (state, { data }) => {
			return {
				...state,
				items: updateAliasForRouting(
					[...state.items] as Phonenumber[],
					data.phonelineId,
					data.alias
				),
			};
		},
		FAXLINE_SET_ALIAS_PENDING: (state, { data }) => {
			return {
				...state,
				items: updateAliasForRouting([...state.items] as Phonenumber[], data.faxlineId, data.alias),
			};
		},
		GROUP_ALIAS_SET_PENDING: (state, { data }) => {
			return {
				...state,
				items: updateAliasForRouting([...state.items] as Phonenumber[], data.groupId, data.alias),
			};
		},
		DEVICE_ALIAS_SET_PENDING: (state, { data }) => {
			return {
				...state,
				items: updateAliasForRouting([...state.items] as Phonenumber[], data.deviceId, data.alias),
			};
		},
		NEW_ADDRESS_MOVE_SUCCESS: (state, { data, payload }) => {
			if (!payload.updatePhonenumbers) {
				return { ...state };
			}

			return {
				...state,
				items: updateAddressId(
					[...state.items] as Phonenumber[],
					data.sourceId,
					payload.address.id
				),
			};
		},
	},
	initialState
);

export const reducer = (state: PhonenumbersState, action: UnknownAction) => {
	return rtkReducer(legacyReducer(state, action), action);
};
