import { createAction } from '../..';

import api, { paymentApi } from '../../../api';
import { ReduxState } from '../../types';
import { instanaLog } from '../../../third-party/instana';
import { Restriction, RestrictionState } from './types';

const shouldFetch = (
	state: RestrictionState,
	target: string | null,
	restrictions: Restriction[]
) => {
	if (
		state.fetchedFull.some(e => e.target === target) ||
		state.fetchingFull.some(e => e.target === target)
	) {
		return false;
	}

	for (const restriction of restrictions) {
		if (
			state.fetchedSingle.some(e => e.target === target && e.restriction === restriction) ||
			state.fetchingSingle.some(e => e.target === target && e.restriction === restriction)
		) {
			return false;
		}
	}

	return true;
};

export const fetchRestrictions = createAction(
	'RESTRICTIONS_FETCH',
	(restrictions: Restriction[] = []) => ({
		data: { restrictions: restrictions.length === 0 ? null : restrictions, target: null },
		promise: () =>
			Promise.all([
				api
					.fetchRestrictions(undefined, restrictions)
					.then(r => r.items)
					.catch(e => {
						instanaLog.error(e);
						return [];
					}),
				paymentApi
					.getRestrictions(restrictions)
					.then(r => r.items)
					.catch(e => {
						instanaLog.error(e);
						return [];
					}),
			]).then(([apiRestrictions, paymentRestrictions]) => [
				...apiRestrictions,
				...paymentRestrictions,
			]),
		shouldFetch: (state: ReduxState) => shouldFetch(state.restrictions, null, restrictions),
	})
);

export const forceFetchRestrictions = createAction(
	'RESTRICTIONS_FETCH',
	(restrictions: Restriction[] = []) => ({
		data: { restrictions: restrictions.length === 0 ? null : restrictions, target: null },
		promise: () =>
			Promise.all([
				api
					.fetchRestrictions(undefined, restrictions)
					.then(r => r.items)
					.catch(e => {
						instanaLog.error(e);
						return [];
					}),
				paymentApi
					.getRestrictions(restrictions)
					.then(r => r.items)
					.catch(e => {
						instanaLog.error(e);
						return [];
					}),
			]).then(([apiRestrictions, paymentApiRestrictions]) => [
				...apiRestrictions,
				...paymentApiRestrictions,
			]),
	})
);

export const fetchRestrictionsForUser = createAction(
	'RESTRICTIONS_FETCH',
	(target: string, restrictions: Restriction[] = []) => ({
		data: { restrictions: restrictions.length === 0 ? null : restrictions, target },
		promise: () => api.fetchRestrictions(target, restrictions).then(r => r.items),
		shouldFetch: (state: ReduxState) => shouldFetch(state.restrictions, target, restrictions),
	})
);

export const forceFetchRestrictionsForUser = createAction(
	'RESTRICTIONS_FETCH',
	(target: string, restrictions: Restriction[] = []) => ({
		data: { restrictions: restrictions.length === 0 ? null : restrictions, target },
		promise: () => api.fetchRestrictions(target, restrictions).then(r => r.items),
	})
);
